.is-invalid {
  background: #ffcccc !important;
  border: 1px solid red;
}

.panel-default {
  border-color: #ddd !important;
}

.text-label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  margin: 0 0 5px;
  font-size: 17px;
  font-weight: 700;
  color: #77777b;
}

.text-grey {
  font-size: 14px;
  line-height: 24px;
  color: #77777b;
}

.button-loader {
  width: 15px;
  height: 15px;
}

.loader-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.submit-button {
  display: inline-block;
  padding: 10px 20px;
  -webkit-border-radius: 4px;
  border-color: transparent !important;
  background-color: #33cc00 !important;
  background-image: none !important;
  font-family: sans-serif !important;
  color: #fff !important;
  text-shadow: none !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.reset-text {
  color: #337ab7;
  text-decoration: none;
  font-size: 14px;
}

#footer {
  p.copy {
    margin: 0;
    padding: 7px;
    text-align: center;
    a {
      color: #337ab7;
      text-decoration: none;
    }
  }

  p {
    color: #888;
    font-size: 14px;
    line-height: 1.6em;
  }
}

label.error {
  color: red;
  font-weight: bold;
  font-size: 12px;
  margin: 0;
}

.submit-button:disabled {
  opacity: 0.5;
}
