.bpg-header {
  width: 100%;
  padding: 30px 0;
  margin: 0;
  background: url(../../../public/images/bpg-header.png) top center no-repeat
    #00467f;
  background-size: cover;
  height: 30px;
}

#footer {
  width: 100%;
  background: #333;
  position: absolute;
  bottom: initial;
  left: 0;
  z-index: 1;
}

.welcome-title {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 40px;
}

.text-blue {
  color: #3f51b5;
}

.benefit-container {
  padding: 45px 0px;
  background: url(../../../public/images/flag-1a-v3.jpg);
  background-attachment: fixed;
}

.benefit-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 30px;
}

.benefit-section {
  border-radius: 0px !important;
  width: 1300px;
}

.benefit-section:before {
  width: calc(100% - 50px);
  background: white;
  position: absolute;
  height: 10px;
  top: -10px;
  content: "";
  left: 25px;
  opacity: 0.6;
}

.benefit-section:after {
  width: calc(100% - 100px);
  background: white;
  position: absolute;
  height: 20px;
  top: -20px;
  content: "";
  left: 50px;
  opacity: 0.4;
}

.head-title {
  color: white;
  background: #174c7b;
  padding: 12px;
  font-size: 28px;
  line-height: 50px;
  font-weight: 700;
  margin-bottom: 20px;
}

.ss-section-heading2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #b3282a;
  padding: 12px;
  text-align: center;
}

.ss-section-heading {
  font-size: 26px;
  font-weight: 700;

  color: #174c7b;
  padding: 12px;
  text-align: center;
}

.socialSecurity-heading {
  color: white;
  background: #174c7b;
  padding: 12px;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.tsp-2 {
  color: white;
  background: #174c7b;
  padding: 12px;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.paystub-title {
  font-size: 23px;
  font-weight: bold;
}

.form-title {
  margin: 0px 5px;
  font-size: 20px;
  font-weight: bold;
}

.floatingInput {
  font-size: 16px;
  line-height: 20px;
}

.floatingInput-milartry-service {
  font-size: 18px;
  width: 100%;
  margin-inline: 15px;
}

.submit .btn-success {
  background-color: #50ae32 !important;
  padding: 10px 50px;
  box-shadow: 0px 0px 19px 6px #ddd9d9;
}

.submit .btn-success:hover {
  background-color: #25681a !important;
  padding: 10px 50px;
  box-shadow: 0px 0px 19px 6px #ddd9d9;
}

.paystub-text {
  font-size: 22px !important;
}

.paystub-section {
  label {
    cursor: pointer;
  }
  .is-invalid {
    background: #ffcccc !important;
  }
  .form-control {
    font-size: 20px;
  }
  .form-control:focus,
  .fers-section .form-control:focus .tspForm-section .form-control:focus {
    box-shadow: none !important;
    border: 1px solid #ddd !important;
  }

  .form-control,
  .fers-section .form-control .tspForm-section.form-control {
    box-shadow: none !important;
    border: 1px solid #ddd !important;
  }

  .form-select {
    box-shadow: none !important;
    border: 1px solid #ddd !important;
  }
}

.input-wrapper .form-control {
  border-bottom: 1px solid lightgray !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
}

.input-wrapper .form-control:focus {
  background-color: #f1f4ff !important;
  box-shadow: none;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: #ccc;
  transition: left 0.5s ease-out;
}

.sidebar-content {
  position: absolute;
  top: 0;
  left: 100%;
  width: 300px;
  height: 100%;
  background-color: #fff;
  transition: left 0.5s ease-out;
}

.sidebar-content.show {
  left: 0;
}

.showSideBar {
  padding-left: 10px;
  z-index: 9999;
  width: 300px !important;
  overflow: auto;
}

.hideSideBar {
  z-index: 9999;
  overflow: hidden;
}

.hover-div {
  z-index: 99;
  width: fit-content;

  position: absolute;
  height: 100%;
  background: #f7f7f7 !important;
}

.sidebar {
  position: relative;
  overflow: hidden;
}

.card-body {
  margin-left: 53px;
  margin-right: 15px;
  min-height: 80vh;
}

.paystub-section2 {
  .is-invalid {
    background: #ffcccc !important;
    border: 1px solid red;
  }
  label {
    cursor: pointer;
  }
}

.tab-title {
  width: 100%;
  padding: 15px;
  border: 1px solid #000;
}

.employee-section {
  font-weight: bold;
  font-size: 14px;
}

.subSection {
  border: 1px solid #ddd;
}

.table-content {
  width: -webkit-fill-available;
}

.employee-form {
  .payAmounts .form-control {
    padding: 0;
    border: none !important;
    background: #f1f4ff !important;
    color: #2f77e8;
    font-weight: bold;
    font-size: 22px;
  }

  .payAmounts .form-control:focus {
    box-shadow: none !important;
  }

  .highlightNumber .form-control {
    border: none !important;
    background: #f1f4ff;
    color: #2f77e8;
    padding: 0px;
  }

  .highlightNumber .form-control:focus {
    box-shadow: none !important;
  }

  .highlightHalfNumber .form-control {
    border: none !important;
    background: #f1f4ff;
    color: #2f77e8;

    padding: 0px !important;
  }
  .w-60 {
    width: 60px;
  }

  .highlightHalfNumber .form-control:focus {
    box-shadow: none !important;
  }

  .transperentNumber .form-control {
    border: none !important;
    background: #fff;
    color: #2f77e8;

    padding: 0px !important;
  }

  .greyNumber .form-control {
    border: none !important;
    background: #fff;
    color: #ddd;
    width: 60px;
    padding: 0px !important;
  }

  .greyNumber .form-control:focus {
    box-shadow: none !important;
  }

  .transperentNumber .form-control:focus {
    box-shadow: none !important;
  }

  .remove-disableBackgeound:disabled {
    background: transparent !important;
  }
}

.custom-datetime .rdtPicker {
  max-width: 25%;
  font-size: 14px;
}

.fegli-A-btn {
  color: #337ab7 !important;
  text-decoration: none;
}

.fegli-A-btn:hover {
  color: #000 !important;
  text-decoration: underline !important;
}

.red {
  color: red;
}

.light-red {
  color: #e41b17;
}

.red-text {
  text-align: center;
  margin-bottom: -19px;
  font-size: 16px;
  color: red;
  margin-top: 30px !important;
}
.thrift-text-yes {
  padding-left: 8px;
}
.semi-title {
  font-style: italic;
  font-size: 14px;
  font-weight: 700;
}
.sub-title {
  font-size: 14px;
  margin-bottom: 0;
}

.main-title {
  color: #b3282a;
  font-weight: 700;
  margin-bottom: 0;
}

.text-description {
  font-weight: 600;
  text-align: center;
  width: 30%;
  float: left;
}

.sub-description {
  color: #1eb166;
  font-weight: 600;
  margin: 0px auto;
  padding: 15px 0px;
  font-size: 27px;
}

.main-title h6 {
  font-size: 17px;
  font-weight: 700;
  white-space: nowrap;
}

.text-body {
  font-size: 14px;
  border-bottom: none;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 15px;
}

.input-size {
  max-width: 100px;
}

.input-wrapper {
  padding: 2px;
  display: flex;
  align-items: start;
  justify-content: start;
}

.input-box:focus-visible,
.remove-focus:focus-visible {
  outline: none;
  box-shadow: none;
}
.input-box:focus,
.remove-focus:focus {
  outline: none;
  box-shadow: none;
}

.input-box,
.input-box :focus {
  background-color: #f1f4ff !important;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0 3px;
  text-align: right;
  margin-left: 5px;
}

.opt-B-btn {
  width: fit-content;
  color: #337ab7;
  background-color: transparent !important;
  border: none;
  font-weight: 600;
  font-size: 17px;
}

.opt-B-btn:focus {
  color: #337ab7;
  outline: none !important;
  box-shadow: none !important;
}
.opt-B-btn:hover {
  width: fit-content;
  color: #337ab7;

  border: none;
  font-weight: 600;
}

.opt-B-btn:focus-visible {
  color: #337ab7;

  border: none;
  font-weight: 600;
  outline: none !important;
}

.opt-B-btn:active {
  width: fit-content;
  color: #337ab7;

  border: none;
  font-weight: 600;
  outline: none !important;
}
.fegli-section {
  .form-control:disabled {
    background-color: transparent !important;
  }
  .form-control:focus {
    box-shadow: none !important;
    border-bottom: 1px solid #ddd !important;
  }
  tr {
    vertical-align: baseline;
  }
}

.fegli-section2 th {
  padding: 0px !important;
}

.fers-section .form-control:focus {
  box-shadow: none !important;
}

.fegli-section .form-control {
  box-shadow: none !important;
  border-bottom: 1px solid #ddd !important;
}

.fers-income-table tr td:nth-child(-n + 2) {
  border-right: 1px solid #a1a1a1;
  vertical-align: baseline;
  display: table-cell;
}

.dollar-image {
  width: 100%;
  height: fit-content;
}

.form-text-area:focus-visible {
  outline: none;
}

.form-text-area {
  width: -webkit-fill-available;
  background: #f1f4ff !important;
  border: none;
  color: #000;
}

.input-box .form-control:focus {
  border: none !important;
}

.activeTab {
  background: #fff !important;
  width: 100%;
}

.checkbox-text {
  font-size: 22px;
}

.section-head {
  color: #b3282a;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 0;
}
.section-desc {
  font-size: 14px;
  margin-bottom: 0;
}
.section-table {
  font-size: 14px;
  border-bottom: none;
  white-space: nowrap;
  width: 100%;
}

.section-table.padding-left-right-10px tr td,
.section-table.padding-left-right-10px tr th {
  padding-left: 10px;
  padding-right: 10px;
}

.section-table.text-center tr:nth-child(2) {
  text-align: left;
}

.section-table.text-center tr td,
.section-table.text-center tr th {
  text-align: center;
}
.section-table.text-center.first-col-text-left tr td:first-child,
.section-table.text-center.first-col-text-left tr th:first-child {
  text-align: left;
}
.section-table.text-center.all-col-text-left tr td:first-child,
.section-table.text-center.all-col-text-left tr th:first-child {
  text-align: left;
}
.section-table.text-center.second-col-text-left tr td:nth-child(2),
.section-table.text-center.second-col-text-left tr th:nth-child(2) {
  text-align: left;
}

.section-table tr td {
  padding: 2px;
  vertical-align: top;
}

.fegli-section tr td {
  padding: 0px !important;
}

input.section-input-text {
  background-color: #fff;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0 3px;
  text-align: right;
  margin-left: 5px;
}
input.section-input-text:focus {
  outline: none;
}
input.section-input-text.editable {
  background-color: #f1f4ff !important;
}
.section-input-wrapper {
  display: flex;
  align-items: start;
  justify-content: start;
}

.field-bottom-title {
  color: red;
}

.Fers-Btags {
  width: 50%;
  display: block;
  float: left;
}

.container-Fers-Btags {
  text-align: center;
  padding-top: 8px;
  font-weight: bold;
  padding-left: 0px;
  padding-right: 0px;
}

.box-text {
  font-size: 16px;
  font-style: italic;

  text-align: center;
  font-weight: 600;
}

.bold-text {
  font-size: 16px;
}

.Tsp2-heading {
  text-align: center;
  border: 1px solid #333;

  padding: 5px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  background-color: #0a4c7b;
}

.panel-heading {
  font-weight: bold;
  font-size: 23px;
  width: 100%;
  background-color: #f5f5f5;
  border: none;
  box-shadow: none;
  line-height: 55px;
}

.textbox-wrapper {
  background-size: 20px 16px;
  position: relative;
  height: 50px;
  border-radius: 2px 0 0 2px;
  border: 1px solid #cdd9e0;
  background-color: #fff;
  margin-bottom: 12px;
  background-repeat: no-repeat;
  background-position: 5% 50%;
}

.tsp-2-textbox {
  font-size: 18px;
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}

.ss-about-social-section {
  width: 100%;
  border: 1px solid;
  padding: 8px;
}

.ss-about-title {
  font-size: 17px;
  font-weight: 700;
}

.ss-Access-gov {
  text-align: center;
  width: 100%;
}

.ss-Access-gov-text {
  font-size: 17px;
  font-weight: 700;
  white-space: break-spaces;
}

.ss-recieve-currrent-text {
  font-size: 22px;
}

.start-icon {
  color: #b32017;
  font-size: smaller;
  margin-right: 5px;
}

.upperCaseTitle {
  font-size: 20px;
  color: #2568a1;
  width: fit-content;
  font-weight: 700;
}

.fund-analysis-header {
  border: 3px solid #333;
  padding: 5px;
  font-size: 22px;
  color: #fff;
  font-weight: 700;
  background-color: #a72b2c;
}

.fund-analysis-body {
  border: 3px solid #333;
  background-color: #eee;
  padding: 15px;
  font-size: 14px;
  font-weight: 700;
  border-top: none;
}

.fund-analysis-text {
  font-size: 20px;
}

.socialSecurity label {
  cursor: pointer;
}

.rainyDayFund label {
  cursor: pointer;
}

.fers-section label {
  cursor: pointer;
}

.family-status-inputs {
  padding-inline-start: 38px;
}

.fengli-title {
  color: #b3282a;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 0;
}

.invisibleSelectBox {
  color: #0069e5;
  border: none;
  border-bottom: 1px solid #000 !important;
}

.invisible-input {
  padding: 0px;
  text-align: end;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid #000 !important;
}

.invisible-input:disabled {
  padding: 0px;
  text-align: end;
  font-weight: bold;
  border: none;
  background-color: transparent;
}

.fegli-Eligible-member-text {
  background-color: #eee;
  border: 1px solid #000;
  padding: 10px;
  font-size: 14px;
  margin-top: 20px;
}

.fegli-span {
  font-weight: 700;
  font-size: 17px;
  color: #000;
}

.fegli-section .first-col-text-left th:first-child {
  text-align: left;
}

.fegli-section .first-col-text-left tr td:first-child {
  text-align: left;
}

.fegli-section .first-col-text-left table tr {
  text-align: center;
}

input.input-box.fegli-Continue.form-control {
  height: 35px;
  font-size: 23px;
  font-weight: 700;
}

.fers-h6 {
  font-weight: 700;
  font-size: 18px;
}

.fers-sub-heanding {
  font-weight: 700;
  font-size: 18.5px;
  text-align: left;
}

.border-dark {
  border-top-width: 3px !important;
}

.fers-input {
  border: 1px solid #cdd9e0 !important;
}

.fers-p {
  font-size: 14px;
}

.fers-b {
  font-size: x-large;
}

.fegli-input-small {
  font-weight: 700;
  font-size: 14px;
  width: 90px;
}

.input-end {
  display: flex;
  justify-content: flex-end;
}

.fers-input-large {
  font-weight: 700;
  font-size: 18px;
}

.section-table tr td {
  padding: 0px !important;
}

.padding-none tr td {
  padding: 0px !important;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  padding: 0rem 0.75rem;
}

.form-floating > .form-select {
  height: calc(3rem + 2px);
  line-height: 3.5;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  height: calc(2.9rem + 2px);
  padding-top: 10px;
  line-height: 1;
}

.form-floating > label {
  padding: 0.5rem 0.75rem;
}

.sideSection {
  .collapse-nav-td {
    transition: width 0.25s;
    width: 3%;
    vertical-align: top;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    position: absolute;
    height: 100%;
    z-index: 200;
    overflow: hidden;
    min-height: 50vh;
    min-width: 37px;
  }

  .actionPanelHead,
  .actionPanelFooter {
    padding: 10px;
    background-color: #fff;
    font-size: 24px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    font-weight: 500;
    color: #444;
    font-family: inherit;
  }

  .screencompleted-area .actionPanelBody {
    padding: 0;
  }

  .actionPanelBody {
    font-size: 24px;
    color: #444;
    font-family: inherit;
  }

  .screenLinks li {
    margin: 0;
    padding: 5px 0;
  }

  ul,
  ol {
    margin: 0 0 20px 20px;
  }

  ul {
    list-style: square;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-align: left;
    background: transparent;
  }

  .screenLinks li.active {
    background-color: #fff;
    font-weight: bold;
    padding-left: 0 !important;
  }

  .screenLinks li {
    margin: 0;
    padding: 5px 0;
  }

  p,
  ul li {
    line-height: 1.4;
  }

  li {
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-align: left;
    background: transparent;
  }

  p,
  ul li {
    line-height: 1.4;
  }

  .screenLinks li a.screen-link {
    width: 100%;
    display: block;
    padding: 0 5px;
    padding-left: 10px;
  }

  span {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-align: left;
    background: transparent;
  }

  a {
    color: #337ab7;
    background-color: transparent;
    text-decoration: none;
    margin: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-align: left;
    background: transparent;
  }

  .screen-list-item {
    cursor: pointer;
    width: auto;
    white-space: nowrap;
  }

  .fa-check-square-o {
    color: #50ae32;
  }

  .collapse-nav-td.open {
    transition: width 0.25s;
    width: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .screenLinks li a.screen-link {
    width: 100%;
    display: block;
    padding: 0 5px;
    padding-left: 10px;
  }

  .screenLinks li a .fa-square-o {
    color: #ccc;
  }
  .screen-title {
    padding-left: 5px;
  }
  .collapse-nav-td .screen-title {
    display: none;
  }
  .open .screen-title {
    cursor: pointer;
    display: initial;
  }

  .screenLinks li a.screen-link {
    padding-left: 10px;
  }

  .open li.sub-screen .screen-link {
    padding-left: 35px;
  }

  .open li.sub-compact-screen .screen-link {
    padding-left: 65px;
  }
}

.single-field {
  padding: 0 32px;
  margin-bottom: 10px;
}

.single-container {
  width: 80%;
  margin: 0px auto;
  margin-bottom: 50px;
  border-bottom: 3px solid;
}

.image-continer {
  text-align: center;
  width: 20%;
  float: left;
}

.amount-text {
  color: #bbbbbb;
  font-weight: 600;
  margin: 0px auto;
  padding: 15px 0px;
  font-size: 27px;
}

.image-size {
  display: inline;
  margin: 2px;
}

.title-text {
  white-space: nowrap;
  font-size: 24px;
  font-weight: 600;
  height: 65px;
}

.description-text {
  font-size: 18px;
  display: flex;
  align-items: center;
  font-weight: 600;
  margin: 0px auto;
  padding: 2px 0px;
  border-bottom: 2px solid black;
}

.price-text {
  text-align: center;
  font-weight: 600;
  margin: 0px auto;
  background-color: #bbd5ec;
  padding: 5px;
  display: inline-block;
  width: 90%;
}

.text-title {
  text-align: center;
  font-size: 15px;
}

.text-item {
  text-align: center;
  font-size: 15px;
}

.qs-box {
  background-color: #dfebf7;
}

.question {
  margin-bottom: 0 !important;
}

.summary-container {
  font-size: 16px;
  padding-top: 25px;
}

.border-bottom-2 {
  border-bottom: 2px solid;
}

.section-content {
  margin: 0px;
  list-style-type: circle;
  font-size: 18px;
  font-weight: bold;
}

.summary {
  td {
    white-space: nowrap;
  }
}

.summary-total {
  font-size: 20px;
  font-weight: bold;
}

.summary-total-text {
  font-weight: normal;
  font-size: 16px;
}

.summary-fengli {
  color: #cc0000;
  font-weight: normal;
  list-style-type: none;
  margin-top: 15px;
  font-size: 16px;
}

.summary-annuity {
  color: #32cd32;
  font-size: 20px !important;
  font-weight: 700;
}

.font-22 {
  font-size: 22px;
}

.fers-item {
  color: #cc0000;
  list-style-type: none;
  margin-top: 15px;
  font-size: 16px;
}

.w-68 {
  width: 68%;
}

.summary-annuity-title {
  color: initial;
  font-weight: normal;
  font-size: 16px;
}

.summary-title {
  color: #cc0000;
  margin: 0;
  font-size: 16px;
  text-align: center;
}

.import-note-title {
  text-align: center;
  color: #fff;
  background-color: #000;
  padding: 8px;
  font-size: 20px;
}

.agentnotes {
  font-size: 20px;
  font-weight: normal;
}

.agentnotes-input {
  background: white;
  border: 3px solid #000;
  box-shadow: none;
  color: inherit;
  outline: none;
  width: 100%;
  font-style: italic;
  resize: none;
  font-size: 20px;
}

.gap-container {
  width: 70%;
  margin: 0px auto;
  border-bottom: 3px solid;
}

.gap-section .group-title {
  font-size: 27px;
  color: #04294b;
}

.gap-section .section {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #999;
  margin-bottom: 15px;
}

.gap-section .section-table-summary {
  font-size: 18px !important;
  border-bottom: none;
  white-space: normal;
  width: 100%;
  margin-bottom: 15px;
  margin: 0;
}

.gap-section table tr td {
  font-size: 18px !important;
  font-weight: 500;
}
.gap-section table tr th {
  font-size: 18px !important;
}

.gap-section .tbl-heading {
  color: #0a4c7b;
  font-size: 18px !important;
}

.gap-section .section-head {
  color: #0a4c7b;
  font-size: 18px !important;
  font-weight: 700;
}

.gap-section .section-table-summary tr td {
  padding: 2px;
}

.gap-section .small {
  font-size: 85%;
}

.gap-section .tbl-heading tr {
  border-bottom: 2px solid;
}

.section-table-summary th {
  padding: 0px !important;
}

.section-table-summary th.col-2 {
  width: 32% !important;
}

.section-table-summary .col-2 {
  width: 32% !important;
}

.section-table-summary .col-5 {
  width: 48% !important;
}
.section-table-summary .col-1 {
  width: 5% !important;
}
.section-table-summary .col-4 {
  width: 20% !important;
}
.red-font {
  color: #c00000;
}

.item-of-imp {
  font-size: 22px;
  text-align: center;
  background-color: #999;
  color: #fff;
  font-weight: 500;
  padding: 5px;
}

.fers-items {
  color: #cc0000;
  list-style-type: none;
  margin-top: 15px;
  font-size: 16px;
  font-weight: normal;
}

.font-weight-bold {
  font-weight: bold;
  color: #000;
  font-size: 18px !important;
}
.summary-annuity {
  color: #32cd32;
  font-size: 18px;
  font-weight: 700 !important;
}

.import-note-title {
  text-align: center;
  color: #fff;
  background-color: #000;
  padding: 8px;
  font-size: 20px;
  font-weight: 500;
}

.item-of-imp-sec {
  border: 2px solid #741213;
  margin: 0 45px 15px 45px;
  margin-top: 15px;
  padding: 10px;
}

.section-group {
  background-color: #5b9bd5;
  padding: 15px;
  border: 2px solid #04294b;
  margin-bottom: 15px;
}

.section-group2 {
  background-color: #c00000;
  padding: 15px;
  border: 2px solid #04294b;
  margin-bottom: 15px;
}

.summary-total .bold-total {
  font-size: 22px !important;
  font-weight: bold !important;
}

.font-weight-bold .bold-total {
  font-size: 22px !important;
  font-weight: bold !important;
}

.col-2.indent {
  padding-left: 5% !important;
  white-space: normal;
}

.fegli-box.text-center tr td:nth-child(2) {
  text-align: left;
}

.fegli-box.text-center tr th:nth-child(2) {
  text-align: left;
}

.small-rainy-text {
  font-size: 14px;
}

.textbox-wrapper select {
  height: 100%;
  border: 0;
  font-size: 20px;
  padding: 16px 15px 0 15px;
  width: 100%;
  position: relative;
  z-index: 99;
  background: transparent;
  box-shadow: none;
}

.textbox-wrapper .floating-placeholder {
  font-size: 18px;
  color: #555;
  letter-spacing: 0.8px;
  z-index: 1;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 15px;
  transition: all 0.2s ease;
}

.textbox-wrapper select.has-val + .floating-placeholder {
  font-size: 12px;
  top: 2px;
}

.summary-section {
  background-color: #dfebf7;
}

.tbl-heading {
  margin-bottom: 15px;
}

.box-title {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  margin: 0;
}
.box-sub-title {
  text-align: center;
  font-style: italic;
  margin-bottom: 15px;
  font-size: 20px;
}

.content-box {
  text-align: center;
  background: white;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 1220px;
  margin: 0 auto;
  position: relative;
  border: 2px solid #333;
  padding: 15px;
}

.benefitForm-section {
  background-color: #dfebf7;

  .box-section {
    margin-bottom: 5px;
    border-bottom: 2px solid #000;
    .sub-title {
      font-size: 24px !important;
      color: #3b7eb3;
      font-weight: 600;
      padding-left: 10px;
    }

    .title {
      font-size: 24px !important;
    }
  }
}

.tbl-second {
  border: none;
  width: 100%;
}

.tbl-second tr:first-child th {
  width: 50%;
  text-align: center;
}

.second-tbl-first-row th:first-child {
  border-right: 2px solid #333;
  font-size: 30px !important;
}
.tbl-second tr:first-child th {
  width: 50%;
  text-align: center;
}

.second-tbl-first-row th:nth-child(2) {
  width: 50%;
  text-align: center;
  font-size: 30px !important;
}

.border-footer {
  border-bottom: 2px solid #333;
  padding-bottom: 5px;
}

.tbl-second .protection-th {
  width: 50% !important;
  text-align: center;
  font-size: 20px;
  border-right: 2px solid #333;
}

.protection-sub-th {
  border: none;
  width: 100%;
  margin: 25px 0;
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  font-size: 22px;
}

.tbl-second .protection-th2 {
  width: 50% !important;
  text-align: center;
  font-size: 20px;
}

.protection-sub-th2 {
  border: none;
  width: 100%;
  margin: 25px 0;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  font-size: 22px;
}

.protection-sub-th tr {
  margin: 10px 0;
}

.protection-th tbody tr th {
  font-size: 22px !important;
}

.protection-th2 tbody tr th {
  font-size: 22px !important;
}

.greeny {
  text-align: center;
  color: #16cf5a;
}

.border-box {
  border-right: 2px solid #333;
  vertical-align: baseline;
}

.greybox {
  width: 80%;
  height: 100%;
  border: 1px solid #666;
  border-radius: 50px;
  margin: auto;
  background-color: #d9d9d9;
  font-size: 22px;
  margin-top: 15px;
}

.grey-box-list1 {
  margin: 15px 40px;
  list-style-type: disc;
  text-align: left;
  padding: 0;
}

.black-strip {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  font-weight: bold;
  font-size: 16px;
}

ul.style-star {
  list-style-type: none;
  position: relative;
}

.greybox ul li {
  font-size: 20px !important;
}

ul.style-star li:before {
  content: "*";
  position: absolute;
  left: -10px;
  top: 4px;
}

.tbl-second .first-width {
  width: 20% !important;
}

.tbl-second .second-width {
  width: 30% !important;
  text-align: center;
}

.tbl-second table tr th,
.tbl-second table tr td {
  padding: 0 !important;
}
.benefits-btn {
  font-size: 20px !important;
  height: auto;
  text-align: center;
  padding: 15px;
  box-sizing: border-box;
  width: 260px;
  margin: auto;
}

.btn.btn-next {
  font-size: 20px;
  height: auto;
  text-align: center;
  padding: 15px;
  background: #50ae32;
  color: white;
  display: block;
  box-sizing: border-box;
  width: 260px;
  margin: auto;
  margin-top: 10px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 23%), 0 3px 6px 0 rgb(0 0 0 / 16%);
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: inherit;
}

.btn.btn-next:hover {
  background: #25681a;
  color: #fff;
}

.btn.btn-next:focus {
  box-shadow: none !important;
  border: none !important;
}

.btn.btn-next:focus-visible {
  border: none !important;
}

.benefits-btn:focus {
  box-shadow: none !important;
}
.btnSeeIfIQualify {
  background-color: #00467f !important;
}
.btnSeeIfIQualify:hover {
  background-color: #014073 !important;
}

.retire-image {
  width: 100%;
}
.retirement .qs-box {
  background-color: #fff;
}

.retirement-container {
  padding-top: 32px;
}

.retirement-label {
  padding-right: 6px;
  font-size: 18px !important;
  color: #333;
  font-weight: normal;
  font-family: inherit;
  cursor: pointer;
  padding-block-start: 4px;
}

.retirement-label2 {
  font-size: 18px !important;
  color: #333;
  font-weight: normal;
  font-family: inherit;
  cursor: pointer;
}

.retirement-2 {
  min-height: 66vh;
  i.fa {
    color: #bdbdbd;
    cursor: pointer;
  }
  .fa-square-o:before {
    content: "\f096";
  }

  i.fa.fa-check-square-o.checker {
    font-size: 22px;
    color: green;
  }
  i.fa.fa-square-o.checker2 {
    font-size: 22px;
  }
}

.screen-area .qs-box-more .question-more {
  margin: 0;
  padding-left: 42px;
  border-top: 20px solid #fff;
  border-right: 32px solid #fff;
  border-bottom: none;
  border-left: 57px solid #fff;
}
.qs-box-more .question-more {
  color: #fff;
  background-color: #0a4c7b;
  text-align: center;
  padding: 16px 60px 14px 32px;
  margin-bottom: 25px !important;
  font-size: 28px;
  font-weight: 700;
}

.answer-more.check-value.answer-checkbox {
  cursor: pointer;
}
.qs-box .answer .answer-summary.fa-check-square-o:before {
  color: #25681a;
}
.qs-box-more .answer-more .answer-summary-1:before {
  margin-right: 10px;
  font-weight: bold;
  color: green;
  font-family: FontAwesome;
}

.qs-box-more .answer-more .answer-summary-2:before {
  margin-right: 10px;
  font-weight: 400;
  color: #bdbdbd;
  font-family: "Font Awesome 6 Free";
}
.success-background {
  background: rgb(202, 231, 193);
  width: 100%;
}
.floatingInput-about {
  color: gray !important;
  font-weight: 400;
  font-size: 18px;
}
.about-teaxtarea {
  height: 100% !important;
}
.text-retire {
  height: 100%;
  border: 0;
  font-size: 20px;
  padding: 16px 15px 0 15px;
  width: 100%;

  z-index: 2;
  background: transparent !important;
  box-shadow: none;
}

i.answer-summary-1.fa.fa-check-square-o.check1 {
  background: rgb(202, 231, 193);
  width: 100%;
}

.qs-box-more .answer-more .answer-summary-1 {
  line-height: 2;
  padding: 10px 32px;
  position: relative;
  box-sizing: border-box;
  font-size: 22px;
  font-family: inherit;
}

.qs-box-more .answer-more .answer-summary-2 {
  line-height: 2;
  padding: 10px 32px;
  position: relative;
  box-sizing: border-box;
  font-size: 22px;
  font-family: inherit;
}

.screen-area .qs-box-survey .question-survey {
  margin: 0;
  padding-left: 42px;
  border-top: 20px solid #fff;
  border-right: 32px solid #fff;
  border-bottom: none;
  border-left: 57px solid #fff;
}
.qs-box-survey .question-survey {
  color: #fff;
  background-color: #0a4c7b;
  text-align: center;
  margin-bottom: 25px !important;
}
.qs-box-survey .question-survey {
  padding: 16px 60px 14px 32px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
}

.survey-p {
  font-size: 22px;
  color: #333;
  font-weight: 600;
  margin: 0;
  font-family: inherit;
}

.answers-survey label {
  color: #333;
  font-size: 22px;
  font-weight: 700;
}

.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400 !important;
  vertical-align: middle;
  cursor: pointer;
}

.radio-inline input {
  margin-right: 3px;
}

.protection-section {
  min-height: 66vh;
  .invalid-feedback {
    font-size: 12px;
    color: red;
  }
  .form-label {
    font-size: 16px !important;
    font-weight: 700;
    color: #77777b;
  }
  .form-control {
    height: 34px;
    color: #757575;
    background-color: #fafbfc;
    border: 1px solid #ddd;
  }

  .form-contro:focus,
  .form-select:focus {
    box-shadow: none !important;
    border-color: #66afe9 !important;
    outline: none !important;
  }

  .form-control:valid:focus,
  .form-control:valid:focus,
  .form-select:valid:focus {
    box-shadow: none !important;
    border-color: #66afe9 !important;
    outline: none !important;
  }

  .form-contro:focus-visible,
  .form-select:focus-visible {
    box-shadow: none !important;
    border-color: #66afe9 !important;
    outline: none !important;
  }

  .form-select {
    height: 34px;
    padding: 0.2rem 2.25rem 0.375rem 0.75rem;
    color: #757575;
    background-color: #fafbfc;
    border: 1px solid #ddd;
  }
  .premium-label {
    font-size: 12px !important;
    font-weight: normal;
  }

  hr {
    height: 0;
    box-sizing: content-box;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #836666;
  }
  .submit-btn {
    color: #fff !important;
    background-color: #337ab7 !important;
    border-color: #2e6da4 !important;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-family: inherit;
    background: 0 0;
  }
  .table {
    display: block;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
  }
  .table th,
  td {
    font-size: 16px !important;
  }
  .pencil {
    color: #337ab7;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  .cancel-btn {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 4px;
  }

  .cancel-btn:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
  .delet-text {
    font-size: 14px !important;
    color: red;
    display: flex;
    cursor: pointer;
    padding: 0px 10px;
  }
  .delet-text:hover {
    text-decoration: underline;
  }
  .active {
    background-color: #f5f5f5;
  }
}

.c-input {
  display: block;
  margin-bottom: 15px;
  font-size: 14px;
  width: 100%;
  padding: 0.375rem 0.75rem;

  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fafbfc !important;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}

.c-input:focus {
  box-shadow: none;
  border-color: #66afe9 !important;
}

.another-referral {
  padding-left: 5%;
}
.form-group {
  margin-bottom: 15px;
}

.btn-link {
  color: #337ab7;
  font-size: 14px;
  text-decoration: none;
  background-color: transparent;
}
.btn-link:hover {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link:focus {
  box-shadow: none;
}

.email-report-section .qs-box {
  margin: auto;
  background: white;
  width: 100%;
  position: relative;
  padding-bottom: 15px;
}

.email-report-section .screen-area .qs-box .question {
  margin: 0;
  padding-left: 42px;
  border-top: 20px solid #fff;
  border-right: 32px solid #fff;
  border-bottom: none;
  border-left: 57px solid #fff;
}
.email-report-section .qs-box .question {
  color: #fff;
  background-color: #0a4c7b;
  text-align: center;
  margin-bottom: 25px !important;
  padding: 16px 60px 14px 32px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
}

.email-report-section .sub-form {
  padding-right: 0 5%;
}

.email-report-section .input-div {
  margin-bottom: 30px;
  text-align: center;
}

.email-report-section .input-label {
  cursor: pointer;
  font-size: 18px !important;
  font-weight: inherit;
  margin: 0;
  color: inherit;
  font-family: sans-serif;
}

.email-report-section .report-section-input {
  margin-right: 10px;
  height: 18px;
  width: 18px;
  margin-top: 2px;
}
.email-report-section .textbox-wrapper {
  border-radius: 3px;
  background-color: #fafbfc;
}

.email-report-section .text-box-input {
  background-color: transparent;
}

.email-report-section .attach-email-wrapper {
  margin: 20px 0;
}

.email-report-section .attach-email-p {
  font-size: 22px !important;
  font-weight: 700;
  color: #04294b;
  text-align: left;
}

.email-report-section .attach-email-ul {
  list-style-type: none;
  margin-left: 5%;
  font-size: 22px;
}

.email-report-section .attach-email-label {
  cursor: pointer;
  font-size: 22px !important;
  font-weight: inherit;
  margin: 0;
  color: inherit;
  display: flex;
  align-items: center;
  font-family: sans-serif;
}

.email-report-section .attach-email-input {
  margin-right: 15px;
  height: 22px;
  width: 22px;
}

.complete-planner-wrapper {
  text-align: center;
}

.msg {
  margin-left: -25px;
  margin-top: 45px;
  margin-bottom: 15px;
  text-align: center;
}

.btn-next {
  width: 300px;
  display: inline;
  margin-left: -25px;
}

.complete-btn {
  display: block;
}

.textbox-wrapper input,
.textbox-wrapper textarea,
.textbox-wrapper select {
  height: 100%;
  border: 0;
  font-size: 20px;
  padding: 16px 15px 0 15px;
  width: 100%;
  position: relative;
  z-index: 99;
  background: transparent;
  box-shadow: none;
}

.email-report-a {
  color: #337ab7;
  text-decoration: none;
}

.email-report-a:hover {
  color: #23527c;
  text-decoration: underline;
}

.email-report-floating-label {
  margin-left: 25%;
  width: 50%;
}

.email-report-floating-placeholder {
  font-size: 18px;
  color: #555;
  letter-spacing: 0.8px;
  z-index: 1;
}

.survey-remove {
  width: fit-content;
  background-color: transparent;
  color: #337ab7;
  border: none;
  cursor: pointer;
  margin-left: auto;
}

.pension-remove-text {
  font-size: 14px;
}

.survey-remove:hover {
  color: #23527c;
  width: fit-content;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-left: auto;
}

.survey-remove:focus:active {
  border: none;
  box-shadow: none !important;
}

.survey-remove:focus-visible {
  border: none;
  box-shadow: none;
}

.client-survey-section .survey-hr hr {
  margin-top: 15px !important;
  border-top-color: #333;
  margin-bottom: 30px !important;
  opacity: 1 !important;
  background-color: #333 !important;
}

.survey-top-hr {
  opacity: 0.1;
}

.purpose-b {
  font-style: italic;
}

.email-report-section {
  min-height: 66vh;
  .form-control.is-invalid {
    background-image: none;
    background: #ffcccc !important;
  }
}
.hide {
  display: none !important;
}

.qs-box-more-continue {
  max-width: 875px;
  margin: auto;
  background: white;
  width: 100%;
  position: relative;
  padding-bottom: 15px;
}
.qs-box-more-continue {
  max-width: 100% !important;
}

.qs-box-more-continue .question {
  margin: 0;
  padding-left: 42px;
  border-top: 20px solid #fff;
  border-right: 32px solid #fff;
  border-bottom: none;
  border-left: 57px solid #fff;
}
.qs-box-more-continue .question {
  color: #fff;
  background-color: #0a4c7b;
  text-align: center;
  margin-bottom: 25px !important;
}
.qs-box-more-continue .question {
  padding: 15px 60px 14px 32px;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.5;
}
.subform .answers {
  padding-left: 25px;
}
.sub-section {
  border: 1px solid #ccc;
  margin: 0 32px;
  margin-bottom: 20px;
}

.qs-box-more .check-value {
  display: flex;
  flex-direction: column;
  margin: 0px -30px;
}

.qs-box-more-continue .question {
  margin: 0;
  padding-left: 42px;
  border-top: 20px solid #fff;
  border-right: 32px solid #fff;
  border-bottom: none;
  border-left: 57px solid #fff;
}

.sub-section .question {
  border: none !important;
  background-color: #f6f6f6;
  color: inherit;
  text-align: left;
  padding-left: 32px !important;
  padding: 5px;
  font-size: 23px;
}

.about-continue-check-text {
  color: #333 !important;
  font-weight: normal;
  font-size: 22px !important;
  font-family: inherit;
}

.about-textarea {
  height: auto !important;
}
.answers {
  padding-left: 25px;
}

.personal-text-wrapper {
  border: 3px solid #000;
}

.personal-heading {
  text-align: center;
  color: #fff;
  background-color: #000;
  padding: 8px;
  font-size: 20px;
  border: 3px solid #fff;
  font-weight: 700;
}

.personal-sub-heading {
  color: red;
  font-size: 20px;
  padding: 8px;
  font-weight: normal;
}

.text-about {
  color: #333;
  font-weight: normal;
  font-size: 22px;
  font-family: inherit;
}

.answers-fers-Ann .sub-section-fers-Ann {
  border: 1px solid #ccc;

  margin-bottom: 20px;

  .error-text {
    font-size: 12px;
    font-weight: bold;
    color: red;
  }
}

.client-survey-section {
  .error-text {
    font-size: 12px;
    font-weight: bold;
    color: red;
  }
}

.question-fers-Ann {
  background-color: #f6f6f6;
  padding: 5px 32px;
  font-size: 23px;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 25px !important;
  font-weight: 700;
  line-height: 1.5;
}

.panel-heading-fers {
  font-size: 18px;
  text-align: center;
}
.panel-primary {
  height: 90%;
  border-color: #337ab7 !important;
}
.panel-primary-ann {
  height: 90%;
  border-color: #8fc97c !important;
}
.penal-container {
  padding: 0px 25px;
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.panel-body {
  padding: 10px 10px 0px 10px;
}

.panel-primary > .panel-heading-fers {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.panel-primary-ann > .panel-heading-fers {
  color: #fff;
  background-color: #50ae32;
  border-color: #8fc97c;
}

.panel-heading-fers {
  padding: 10px 15px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.question-fers-Ann .single-field-ann {
  padding: 0 !important ;
}

.panel-heading-sub {
  background-color: #50ae32;
  border-color: #8fc97c;
  color: white;
  font-size: 18px;
  text-align: center;
  padding: 10px 15px;
}
.panel-sub-body {
  height: 85px;
  border: 2px solid #8fc97c;
}

.textbox-wrapper .hint {
  width: 3%;
  position: absolute;
  right: 1.3px;
  top: 1px;
  z-index: 3;
  background: #ffff;
  height: 92%;
  display: flex;
  align-items: center;
}

.hint {
  color: #9e9e9e;
  font-size: 20px;
  cursor: pointer;
}

.fers-span {
  color: red;
  font-size: 90%;
}

.sub-section-fers-Ann {
  border: 1px solid #ccc;

  margin-bottom: 20px;
}

.single-field-ann {
  padding: 0 20px;
}

.fers-about-input {
  border: 1px solid #cdd9e0 !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.thrift-input {
  border: 1px solid #c5c5c5 !important;
}

.thrift-textbox {
  padding: 0 16px;
}

.thrift-text {
  color: #333;
  font-weight: normal;
  font-size: 22px;
  font-family: inherit;
}

.single-field-thrift {
  padding: 0 16px;
}

.thrift-a {
  color: #337ab7;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 12px;
}

.thrift-a:hover {
  color: #23527c;
  text-decoration: underline;
}

.thrift-image-container {
  text-align: center;
  border: 1px solid #333;
  padding: 5px;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  background-color: #0a4c7b;
}
.thrift-img-sub {
  margin-bottom: 15px;
  text-align: center;
}

.thrift-image {
  display: inline-block;
  width: 100%;
}

.haveHint {
  border: none;
}
.thrift-section {
  border: 2px solid #333;
  padding: 10px;
  margin: 0;
  border-bottom: none;
  background-color: #b3282a;
}

.thrift-section-p {
  font-size: 16px;
  color: #fff;
}

.thrift-risk-section {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 12px;
}

.thrift-risk-section-space {
  display: flex;
}

.thript-risk-section-p {
  white-space: nowrap;
}

.thrift-risk-section-2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 3px solid;
}

.thrift-risk-section-2-sub {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
}

.thrift-risk-section-2-sub2 {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: center;
}

.thrift-risk-section-2-sub3 {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: flex-end;
}
.thrift-section-2 {
  border: 2px solid #333;
  padding: 10px;
}
.thrift-aggressive-check {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 12px;
}

.thrift-aggressive-label {
  margin-left: 0;
  padding-left: 0;
}

.thrift-capitalizing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
}

i.fa.fa-check-square-o.thrift-checker {
  color: green;
  margin-inline: 5px;
}

i.fa.fa-square-o.thrift-checker2 {
  margin-inline: 5px;
}

.hidden-radio-check {
  display: none;
}

.remove-align {
  padding-right: 20px;
  text-align: right;
}

.remove-text {
  font-size: 11.9px !important;
}

.thrift-label {
  font-size: 18px;
  color: #555;
  letter-spacing: 0.8px;
}

.thrift-input-focus {
  box-shadow: none !important;
  font-size: 20px;
}

.has-val:focus-visible {
  outline: none;
}

.about-inputs {
  border: 1px solid #c5c5c5 !important;
  font-size: 20px;
}

.about-input-wrapper {
  background-size: 20px 16px;
  position: relative;
  height: 50px;
  background-color: #fff;
  margin-bottom: 12px;
  background-repeat: no-repeat;
  background-position: 5% 50%;
}

.about-input-wrapper input {
  height: 100%;
  border: 0;
  font-size: 20px;
  padding: 18px 15px 0 15px;
  width: 100%;
  position: relative;
  color: black;
  box-shadow: none;
}

.about-input-wrapper .hint {
  width: 3%;
  position: absolute;
  right: 1.3px;
  top: 1px;
  z-index: 3;
  background: #f1f4ff !important;
  height: 92%;
  display: flex;
  align-items: center;
}

.about-input-wrapper .hint-disable {
  width: 3%;
  position: absolute;
  right: 1.3px;
  top: 1px;
  z-index: 3;
  background: rgba(204, 204, 204, -0.509804) !important;
  height: 92%;
  display: flex;
  align-items: center;
}

.fersAnn-check:focus {
  box-shadow: none !important;
  border: none !important;
}
.info-popover:hover {
  color: #23527c !important;
  font-size: 19px !important;
}

.info-popover {
  font-size: 19px !important;
  margin-left: 0px;
  color: grey;
}

.penstion-sub-level {
  margin-left: 20px;
  font-size: 19px;
  color: grey;
}

.pension-section {
  min-height: 66vh;
}
input[type="date"] {
  position: relative;
}

/* ID: 40*/
input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

.form-control:disabled {
  background: #cccccc7d !important;
}

.form-control {
  background: transparent;
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #cdd9e0 !important;
}

.fears-formula-table td {
  padding: 0px !important;
}

.expense-form {
  .panel-heading {
    font-weight: bold;
    font-size: 23px;
    width: 100%;
    background-color: #f5f5f5;
    border: none;
    box-shadow: none;
  }

  .small-rainy-text {
    font-size: 14px;
  }

  .expense-container {
    border: 3px solid #333;
    padding: 10px;
    margin: 0;
    border-bottom: none;
    background-color: #b3282a;
  }

  .expense-title {
    font-size: 22px;
    color: #fff;
  }

  .expense-container-2 {
    border: 2px solid #333;
    padding: 15px;
  }
  table {
    font-size: 19px;
  }

  .font-100 {
    font-size: 20px;
    font-weight: bold;
  }

  td,
  tr {
    padding: 5px !important;
  }

  .input-wrapper {
    font-size: 19px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .input-wrapper .form-control {
    background-color: #f1f4ff !important;
    box-shadow: none !important;
    border: 1px solid #c5c5c5 !important;
    font-size: 20px;
    font-weight: normal;
    border-radius: 2px 0px 0px 2px;
    text-align: left;
    width: 90%;
    padding: 8px;
  }

  .form-control:focus {
    border: 2px solid #000 !important;
    border-radius: 5px;
  }

  tr td:nth-child(2) {
    border-right: 1px solid #ccc;
  }

  td:nth-child(3) {
    padding-left: 15px !important;
  }

  .input-box {
    background-color: #f1f4ff !important;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0 3px;
    text-align: right;
    margin-left: 5px;
  }

  .title {
    font-size: 20px;
    font-weight: normal;
  }

  .expense-input-large {
    font-weight: 700;
    font-size: 18px;
  }

  .expense-bold {
    font-size: 22px;
    font-weight: 700;
  }

  .expense-normal {
    font-size: 22px;
  }

  .expense-dark-heading {
    text-align: center;
    color: #fff;
    background-color: #000;
    padding: 8px;
    font-size: 20px;
    border: 3px solid #fff;
    font-weight: 700;
  }

  .expense-dark-text-wrapper {
    border: 3px solid #000;
  }

  .expense-dark-sub-heading {
    color: red;
    font-size: 20px;
    padding: 8px;
    font-weight: normal;
  }

  .expense-normal-space {
    margin-bottom: 37px;
    margin-top: 34px;
  }

  .text-first {
    text-align: end;
  }

  .text-third {
    text-align: start;
  }

  .text-second {
    text-align: center;
  }

  .expense-graph {
    width: 100%;
  }

  .text-align-second {
    font-size: 20px;
    text-align: right;
    font-weight: 700;
  }

  .total-expense {
    .form-control {
      font-weight: bold;
    }
  }
}

.emergency-saving {
  .form-control {
    background: #fff;
    border-radius: 2px 0px 0px 2px;
    border: 1px solid #cdd9e0 !important;
    font-weight: bold;
  }

  .emergency-saving-check {
    display: flex;
    align-items: flex-start;
  }

  .emergency-saving-text {
    font-size: 20px;
  }

  .emergency-saving-label {
    color: #333;
    font-size: 20px !important;
    font-weight: bold !important;
  }

  .emergency-saving-input {
    height: 22px;
  }
  .btn-wrapper {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .emergency-saving-label2 {
    color: #333;
    margin-left: 40px;
    font-size: 20px !important;
    font-weight: bold !important;
  }
  .panel-heading {
    font-weight: bold;
    font-size: 23px;
    width: 100%;
    background-color: #f5f5f5;

    border: none;
    box-shadow: none;
  }
  .emergency-saving-p {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .bold {
    font-weight: 700;
  }
  .emergency-saving-ul {
    font-size: 14px;
    list-style-type: disc;
    font-weight: bold;
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
  }
  .emergency-li {
    margin: 0;
  }
  .emergency-saving-red-text {
    border: 3px solid #333;
    padding: 5px;
    font-size: 22px;
    color: #fff;
    font-weight: 700;
    background-color: #a72b2c;
  }
  .red-text-sub {
    border: 3px solid #333;
    background-color: #eee;
    padding: 5px;
    font-size: 14px;
    font-weight: 700;
    border-top: none;
  }

  .col-text {
    font-size: 22px;
  }

  .emergency-saving-input {
    background-color: transparent;
  }
  .remove-text-savings {
    color: red;
    font-size: 14px;
  }
}

.Tsp-withdraw-opt {
  .Tsp-text {
    font-size: 20px;
  }

  .Tsp-text-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .Tsp-box {
    border: 2px solid #000;
    background: #174c7b;
    padding: 15px 10px;
    color: #fff;
  }
  .box {
    display: flex;
    align-items: center;
  }
  .error-text {
    color: #920f15;
    margin-top: 5px;
    font-size: 20px;
    margin-bottom: 0;
    font-weight: bold;
  }
  .Tsp-red-box {
    border: 3px solid #333;
    padding: 5px;
    font-size: 22px;
    color: #fff;
    font-weight: 700;
    background-color: #174c7b;
  }
  .Tsp-sub-red-box {
    border: 3px solid #333;
    background-color: #eee;
    padding: 5px;
    font-size: 14px;

    border-top: none;
  }
  .Tsp-single-Ann-box {
    padding-left: 50px;
  }
  .Tsp-single-Ann-p-col3 {
    font-size: 18px;
    font-weight: 700;
    color: #a9a9a9;
    margin: 0;
  }
  .Tsp-Text {
    font-size: 18px;
  }
  .Tsp-bold {
    font-weight: 700;
  }
  .Tsp-private-sector-text {
    font-size: 18px;
  }
  .square-icon {
    font-size: 30px;
    width: 30px;
  }
  .Tsp-img2 {
    margin-bottom: 15px;
    width: 100%;
  }
  .Tsp-img1 {
    width: 100%;
  }
  .icon-text {
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
  }
  .greyBox {
    color: #ccc !important;
  }
  .display-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .Tsp-select {
    border: 2px solid #000;
    font-size: 22px;
    border-radius: 0 !important;
  }
  .display-text {
    display: flex;
    align-items: center;
  }
}

.conclusion {
  .floatingInput {
    font-size: 14px !important;
    font-style: italic;
  }

  .box-wrapper {
    padding: 0 0 15px 0;
    border: 1px solid #000;
    background-color: #5b9bd5;
    color: #000;
    font-size: 27px;
  }
  .box-body {
    padding: 15px;
  }
  .body-text-wrapper {
    background-color: #fff;
    border: 1px solid #000;
    font-size: 18px;
    padding: 15px;
    margin-bottom: 15px;
  }
  .small-description {
    font-size: 16px;
  }
  .p-text-wrapper {
    color: #0a4c7b;
    padding: 0 15px;
  }
  .main-p {
    margin-bottom: 0;
    border-bottom: 2px solid #000;
  }
  .sub-p-normal {
    font-weight: 300;
  }
  .error-text {
    color: #cc0000;
  }
  .left-side-message {
    position: absolute;
  }
  .table-border {
    border-bottom: 2px solid #000;
  }
  .table-border-top {
    border-top: 2px solid #000;
  }
  .total-text {
    font-size: 22px;
    font-weight: bold;
  }
  .heading-gray {
    background: gray;
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 10px 0;
  }
  .heading-gray-wrapper {
    padding: 0 15px;
  }
  .heading-gray-ul {
    color: #cc0000;
    list-style-type: none;
    margin-top: 15px;
    font-size: 16px;
  }
  .conclusion-span {
    color: initial;
    font-weight: normal;
    font-size: 16px;
  }
  .conclusion-green {
    color: #16cf5a;
    font-weight: 700;
    font-size: 20px;
  }
  .sub-p {
    font-size: 15.3px;
    font-weight: 700;
  }
  .total-text-red {
    font-size: 20px;
    font-weight: bold;
    color: #cc0000;
  }
  .total-text-greeny {
    font-size: 20px;
    font-weight: bold;
    color: #00b050;
  }
  .conclusion-primary-heading {
    font-size: 22px;
    padding-top: 24px;
  }
  .red-container {
    border: 2px solid #cc0000;
    padding: 10px;
  }
  .warning-image {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
  }
  .warn-text-wrapper {
    text-align: center;
  }
  .warn-text {
    color: #cc0000;
    font-size: 20px;
    font-weight: 700;
  }
  .warn-important {
    display: flex;
    justify-content: center;
  }
  .warning-box-wrapper {
    display: flex;
    align-items: center;
  }
  .warning-section {
    padding: 0 15px;
  }
  .warning-section .row .col-md-10 {
    margin-left: -25px;
  }
  .text-container {
    border: 2px solid #000;
  }
  .normal-text {
    color: #000;
    font-size: 20px;
  }
  .normal-text-wrapper {
    text-align: center;
  }
  .normal-warn {
    list-style-type: none;
    padding-left: 10px;
  }
  .warn-li {
    padding-left: 10px;
  }
  .notes-container {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 8px 0;
    font-size: 20px;
  }
  .notes-text {
    border: 2px solid #000;
    font-size: 20px;
    font-weight: normal;
  }
  .notes-textarea {
    font-size: 20px;
    background: white;
    box-shadow: none;
    color: inherit;
    outline: none;
    width: 100%;
    height: 100%;
    resize: none;
    border: none !important;
  }
  .notes-textarea::placeholder {
    font-size: 20px;
    font-style: italic;
  }

  .text-space {
    padding: 5px 0;
  }
  .col-spacing {
    padding-left: 5%;
  }
  .section-background {
    background: #eee;
    padding: 12px 0 25px 0;
  }
  .conclusion-textarea {
    background-color: #fff;
  }
  .conclusion-textarea:focus {
    box-shadow: none !important;
  }
  .conclusion-input-wrapper {
    padding: 2px;
    display: flex;
    align-items: start;
    justify-content: start;
    font-size: 20px;
  }
  .font-20 {
    font-size: 20px;
  }
  .conclusion-input {
    border-bottom: 1px solid #cdd9e0 !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    text-align: left;
  }
  .conclusion-input:focus {
    box-shadow: none !important;
  }
  .table-texts {
    display: flex;
    align-items: center;
  }
  .text-bold {
    font-weight: 700;
  }
  .fersAnnuity {
    text-align: right;
    font-size: 22px;
    font-weight: bold;
  }
  .text-bold-medium {
    font-weight: 600;
  }
  .conclusion-text {
    color: initial;
    font-weight: normal;
    font-size: 15.3px;
  }
  .heading {
    margin-bottom: 10px;
    font-weight: 700;
    color: #04294b;
    font-family: inherit;
  }

  .icon-gray {
    color: #ccc;
  }

  .tsp-withdraw-value {
    margin-left: 10px;
  }
}

.btn-wrapper {
  margin-top: 30px;
  margin-bottom: 20px;
}

.fersAnnuity {
  text-align: right;
  font-size: 22px;
  font-weight: bold;
}

.custom-datetime input:focus ~ .custom-floating-label label {
  opacity: 0.65;
  transform: scale(0.75) translateY(-0.5rem) translateX(0.15rem);
}

.custom-floating-label {
  position: relative;
}

.custom-floating-label label {
  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 9;
  font-size: 18px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.2s;
}

.custom-floating-label.filled label {
  top: 4%;
  font-size: 19px;
  left: 0%;
  transform: scale(0.7) translateY(-0.5rem) translateX(-0.7rem);
  color: black;
  background: transparent;
  opacity: 0.65;
}

.custom-floating-label input {
  padding: 8.2px;
  padding-bottom: 0px;
  padding-top: 15px;
}

.required-input {
  background: #f1f4ff !important;
}
.is-invalid {
  background: #ffcccc !important;
  border: 1px solid red;
}
.MuiFormLabel-asterisk {
  display: none !important;
}

.normal-picker {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiOutlinedInput-root:hover {
    border: 1px solid #ddd !important;
  }
  .MuiOutlinedInput-root {
    padding-top: 0px;
    font-size: 20px !important;
    border: 1px solid #ddd !important;
  }
  .is-invalid .Mui-error {
    background: #ffcccc !important;
    border: 1px solid #ddd !important;
  }
  .MuiInputBase-input:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .MuiInputLabel-shrink {
    border: none !important;
    margin-top: 10px !important;
    font-size: 19px !important;
  }
  .MuiOutlinedInput-input {
    padding-bottom: 5px !important;
  }
  .Mui-focused {
    color: black !important;
  }
  .Mui-disabled {
    background: #e6e6e6 !important;
    -webkit-text-fill-color: black !important;
  }
  .MuiInputLabel-formControl,
  .MuiInputLabel-formControl .Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.custome-picker {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiOutlinedInput-root:hover {
    border: 1px solid #ddd !important;
  }
  .MuiOutlinedInput-root {
    padding-top: 0px;
    font-size: 20px !important;
    border: 1px solid #ddd !important;
  }
  .is-invalid .Mui-error {
    background: #ffcccc !important;
    border: 1px solid #ddd !important;
  }
  .MuiInputBase-input:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .MuiInputLabel-shrink {
    border: none !important;
    margin-top: 10px !important;
    font-size: 19px !important;
  }
  .MuiOutlinedInput-input {
    padding-bottom: 5px !important;
  }
  .Mui-focused {
    color: black !important;
  }
  .Mui-disabled {
    background: #e6e6e6 !important;
    -webkit-text-fill-color: black !important;
  }
  .Mui-disabled.MuiFormLabel-filled {
    -webkit-text-fill-color: #65686a !important;
  }
  .MuiOutlinedInput-input {
    font-size: 1rem;
  }
  .MuiInputLabel-formControl,
  .MuiInputLabel-formControl .Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
  }
}

.protection-datepicker {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiOutlinedInput-root:hover {
    border: 1px solid #ddd !important;
  }

  .MuiInputBase-input:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  .MuiOutlinedInput-input {
    padding: 5px 8px !important;
  }

  .Mui-disabled {
    background: #e6e6e6 !important;
    -webkit-text-fill-color: black !important;
  }
  .MuiInputLabel-formControl,
  .MuiInputLabel-formControl .Mui-focused {
    color: rgba(0, 0, 0, 0.6) !important;
  }

  .MuiOutlinedInput-root {
    background-color: #fafbfc;
    border: 1px solid #ddd !important;
  }
}

.ml-icon .MuiIconButton-edgeEnd {
  margin-right: 35px;
}

.form-control:valid:focus,
.form-control:valid:focus,
.form-select:valid:focus {
  box-shadow: none !important;
  outline: none !important;
}

.SaveAlertModal {
  max-width: 310px !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}

.success-button {
  font-size: 14px !important;
  padding: 6px 12px !important;
  color: #fff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
}

.discard-button {
  font-size: 14px !important;
  padding: 6px 12px !important;
  color: #fff !important;
  background-color: #d9534f !important;
  border-color: #d43f3a !important;
}

.paystub-section2 {
  .was-validated .form-control:invalid {
    border-color: #dc3545;
  }
}

.text-normal {
  white-space: normal !important;
}

.deleted {
  color: #a9a9a9;
}
.benefits-overView-refresh-button {
  display: flex;
  /* justify-content: center; */
  margin: auto;
  background: #f64f00;
  color: #ffff;
  border: none;
  padding: 10px;
  font-size: 24px;
}

.overview-link,
.overview-link:hover,
.overview-link:focus,
.overview-link:active {
  text-decoration: none;
  color: #ccc !important;
}

.small-loader {
  width: 30px;
  height: 30px;
}

.icon-lg {
  width: 50px;
  height: 50px;
  font-size: 30px;
}

.progressBar .progress-bar {
  background-color: #00b050;
  font-size: 20px;
}

.progressBar {
  border: 1px solid grey;
  height: 50px;
  margin: 10px auto;
}

.offlineSyncIconContainer {
  position: fixed;
  z-index: 1000000;
  background: rgba(251, 251, 251, 0.97);
  padding: 10px;
  font-size: 25px;
  border-radius: 10px;
  height: 100vh;
  width: 100vw;
  top: 0vh;

  padding: 40vh 35vw;
}

.progress-continue {
  font-size: 20px !important;
  background-color: #337ab7 !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.26);
}

select:disabled {
  background: #e6e6e6 !important;
}

#offline-notification img {
  width: 100%; /* ID: 41 */
  height: auto; /* ID: 42 */
}

.loading-screen2 {
  height: 1000px !important;
  text-align: center !important;
}

.client-survey-section .question-next-sep {
  background-color: #ccc !important;
}

.client-survey-section .question-next-sep:hover {
  background-color: #50ae32 !important;
}
