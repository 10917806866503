.overView {
  b {
    font-weight: bold !important;
  }
  .over-view-container {
    width: 50%;
  }
  .social-container {
    width: 75%;
  }
  .field-description-text {
    font-size: 18px;
  }
  .field-description-text2 {
    font-size: 18px;
  }
  .field-bottom-title {
    font-size: 18px;
  }
  .drd-container {
    width: 45%;
    padding-right: 0px;
  }
  .drd-input {
    width: 55%;
    padding-left: 0px;
  }
  .tsp-container {
    display: flex;
  }
  .mo-mt-0 {
    margin-top: 0.25rem;
  }
  .title-bold {
    color: #b3282a;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .title-black {
    font-size: 25px;
    font-weight: 700;
    color: #ffff;
    margin-top: 10px;
    background: #000;
    padding: 10px;
    text-align: center;
  }
  .align-option-c {
    margin-left: 98px;
  }
  .table-td td {
    font-size: 22px;
    vertical-align: top;
  }
  .text-body th {
    font-size: 22px;
  }
  .common-title {
    font-size: 22px;
    margin-bottom: 0;
  }
  .common-title-italic {
    font-size: 22px;
    margin-bottom: 0;
    font-style: italic;
  }
  .text-body .table-td {
    width: 33%;
    font-weight: 700;
    font-size: 18px;
  }
  .table-td-weekly {
    font-size: 18px;
  }

  .form-control {
    font-size: inherit !important;
  }

  .font-20 {
    font-size: 20px;
  }
  .form-control:disabled {
    background: none !important;
  }

  input.input-box.fegli-Continue.form-control {
    height: 35px;
    font-size: 23px !important;
    font-weight: 700;
  }
  .seaction-title {
    font-size: 22px;
    font-weight: 700;
  }
  .sub-title-option {
    font-size: 22px;
    font-weight: 700;
  }
  .minumum-age-content {
    font-size: 22px;
  }

  .input-section {
    min-width: 82px;
  }
  .border-bottom {
    border-bottom: 1px solid #000 !important;
  }
  .section-title {
    font-size: 28px;
    margin-right: 5px;
  }
  .subSection {
    border: 1px solid #ddd;
  }
  .head-title {
    color: white;
    background: #174c7b;
    padding: 12px;
    font-size: 32px;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-left: -10px;
    margin-right: -15px;
  }

  .table-striped tr:nth-child(even) {
    background-color: #f2f2f2 !important;
  }
  .fegli-input-small {
    width: 80px;
  }
  .ml-3 {
    margin-left: 12px;
  }
  .overView-title {
    font-size: 22px;
    font-weight: 700;
  }
  .ul-text {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: 100;
  }
  .section-table td {
    vertical-align: top;
    font-size: 18px;
  }
  .font-17 {
    font-size: 17px;
  }
  .italic-title {
    font-style: italic;
    font-weight: 700;
    font-size: 25px;
  }
  .section-head {
    color: #b3282a;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 0;
  }
  .section-head-title {
    color: #b3282a;
    font-size: 20px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .brown {
    color: #b3282a;
  }

  .radioLable {
    font-size: 22px;
  }

  .font-18 {
    font-size: 18px;
  }

  .title-wrapper {
    width: -webkit-fill-available;

    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .title-wrapper .form-control {
    border-bottom: 1px solid lightgray !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    max-height: 30px;
  }

  .paystub-section .form-control {
    font-size: 18px !important;
  }
  .padding-10 {
    padding: 10px;
  }

  .required-input {
    max-height: 30px;
  }

  .margin-left {
    margin-left: 12px;
  }
  .border-top {
    border-bottom: 2px solid rgb(8, 73, 126) !important;
  }

  .paystub-table tr td {
    font-size: 18px;
  }

  .fears-formula-table input {
    font-size: 18px !important;
  }

  .dispaly-security-data {
    display: block;

    .protection-th {
      margin: auto;
    }
    .title {
      display: flex;
      justify-content: left;
      font-size: 20px;
    }
    .greeny {
      text-align: start !important;
    }
  }
  @media (max-width: 820px) {
    .head-title {
      font-size: 28px;
    }

    .over-view-container {
      width: 80%;
    }
    .overView-title {
      font-size: 20px;
      font-weight: 600;
    }

    .title-black {
      font-size: 20px;
    }
    .italic-title {
      font-size: 20px;
      font-weight: 600;
    }
    .section-head-title {
      font-size: 22px;
    }
    .radioLable {
      font-size: 21px;
    }
    .section-head {
      font-size: 20px;
      font-weight: 600;
    }
    .ul-text {
      font-size: 23px;
    }
    .section-table td {
      font-size: 23px;
    }
    .table-td td {
      font-size: 23px;
      padding: 2px;
    }
    .text-body th {
      font-size: 23px;
    }

    .seaction-title {
      font-size: 26px;
    }
    .minumum-age-content {
      font-size: 23px;
    }
    .drd-container {
      width: 48%;
    }
    .drd-input {
      width: 52%;
    }
  }

  @media (max-width: 416px) {
    .input-wrapper {
      min-width: 60px;
    }
    .over-view-container {
      width: 100%;
    }
    .b-weekly {
      font-size: 18px;
    }
    .title-bold {
      font-size: 20px;
    }
    .align-option-c {
      margin-left: 54px;
    }
    .seaction-title {
      font-size: 17px;
    }
    .head-title {
      font-size: 22px;
      line-height: 20px;
    }
    .minumum-age-content {
      font-size: 14px;
    }
    .paystub-section .form-control {
      font-size: 18px !important;
    }
    .title-black {
      font-size: 16px;
    }
    .field-bottom-title {
      font-size: 14px;
    }
    .field-description-text {
      font-size: 14px;
    }
    .field-description-text2 {
      font-size: 14px;
    }
    .section-head-title {
      font-size: 14px;
    }
    .section-head {
      font-size: 12px;
    }
    .section-head-1 {
      font-size: 12px;
    }
    .overView-title {
      font-size: 18px;
    }
    .table-td td {
      font-size: 12px;
      padding: 2px;
    }
    .common-title {
      font-size: 16px;
    }
    .fersAnnuity {
      font-size: 14px;
    }
    .text-body tr th {
      font-size: 18px;
    }
    .text-body td .form-control {
      font-size: 12px;
    }
    .radioLable {
      font-size: 18px;
    }
    .special-contiainer .form-control {
      max-width: 100px;
    }
    .title-bold {
      font-size: 18px;
    }
    .section-table td {
      font-size: 14px;
    }
    .text-body .table-td {
      font-size: 14px;
    }
    .table-td-weekly {
      font-size: 13px;
    }
    input.input-box.fegli-Continue.form-control {
      height: auto;
      font-size: inherit !important;
    }
    .fers-b {
      font-size: 13px;
    }
    .social-container {
      width: 100%;
    }
    .italic-title {
      font-size: 14px;
    }
    .ul-text {
      font-size: 12px;
    }
    .common-title-italic {
      font-size: 12px;
    }
    .section-title {
      font-size: 20px;
    }
    .dispaly-security-data .title {
      font-size: 14px;
    }
    .dispaly-security-data .title {
      text-align: center;
      display: block;
      padding: 0px;
    }
    .mx-5 {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .adj-text {
      text-align: end;
    }
    .greybox {
      width: 100%;
    }
    .greybox ul li {
      font-size: 14px !important;
    }
    .card-body {
      padding: 0px;
      margin-left: 44px;
    }
    .text-body td {
      padding: 1px 5px;
    }
    .sub-title-option {
      font-size: 18px;
    }
    .drd-container {
      width: 100%;
      text-align: center;
    }
    .drd-input {
      width: 70%;
      margin: 0px auto;
    }
    .paystub-table td,
    .text-body td {
      padding: 1px;
      font-size: 14px !important;
    }
    .paystub-table .form-control {
      font-size: 15px !important;
      padding: 1px !important;
    }
    .tsp-container {
      display: table-cell;
    }
    .mo-mt-0 {
      margin-top: 0px;
    }
    .padding-10 {
      padding: 5px 2px;
    }

    .optionA-custom-w {
      width: 78%;
      padding-right: 0px;
    }
    .optionA-custom-w-2 {
      width: 22%;
      padding-left: 0px;
    }

    .fears-formula-table input {
      font-size: 14px !important;
    }
  }
}
