.planner-container {
  .planner-title {
    color: #fff;
    margin-left: 60px;
    padding: 0px;
    margin-top: -14px;
    font-size: 32px;
    line-height: 1;
  }

  .header {
    width: 100%;
    padding: 40px 0;
    margin: 0;
    background: url(../../../public/images/bpg-header.png) top center no-repeat
      #00467f;
    background-size: cover;
    height: 30px;
    background-size: 2000px;
  }

  .green {
    color: #0c0;
  }

  .black-font {
    color: #c000;
  }

  .article-section {
    padding: 20px;
    margin-bottom: 150px;
  }

  .fw-bold {
    font-weight: bold;
  }

  p {
    font-size: 14px !important;
  }

  p {
    margin-bottom: 20px;
  }

  @media (max-width: 992px) {
    p {
      font-size: 0.85em;
    }
  }

  p {
    font-size: 1em;
    line-height: 1.6em;
    margin: 0 0 20px;
  }

  b,
  strong {
    color: #002060;
  }

  li {
    margin-bottom: 10px;
  }

  .agent-tip b,
  .agent-tip strong,
  .agent-tip {
    color: #c00000 !important;
  }
}
