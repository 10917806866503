@media screen and (min-width: 768px) and (max-width: 1024px) {
  .benefit-section {
    width: 680px;
  }
  #footer {
    position: absolute;
    bottom: initial;
  }
  .payAmounts .form-element {
    margin-left: 10px !important;
    width: 75% !important;
  }
  .panel-heading-fers {
    height: 75px;
    padding: 0px;
  }

  .penal-container {
    padding: 0px;
  }

  .main-title h6 {
    font-size: 15px;
  }

  .fegliForm {
    .col-md-3 {
      width: 50%;
      padding: 10px 20px;
    }
  }

  .single-container {
    width: 100%;
  }

  .gap-container {
    width: 80%;
  }
  .thrift-section-2 .col-md-4 {
    width: 100%;
  }
  .responsive-riskanalysis {
    display: flex;
  }
  .protection-section .form-label {
    font-size: 13px !important;
    font-weight: bold;
  }

  .fegli-container {
    .side-section {
      width: 100% !important;
    }
  }
}

@media (max-width: 416px) {
  .mobile-menu {
    display: none !important;
  }
}
