.App-header {
  width: 100%;
  padding: 30px 0;
  margin: 0;
  background: url(../public/images/page-header_flag.jpg) top center no-repeat
    #00467f;
  height: 30px;
}

html,
body,
#root,
.App {
  height: 100%;
}

.navbar {
  border-top: 1px solid #fff;
  background-color: #101010;
  height: 50px;
  margin-top: 5px;
}

.navbar-brand img {
  padding-right: 15px;
  width: 180px;
  margin-top: -10%;
}

.nav-link,
.navbar-text {
  color: #ccc !important;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 20px;
}
#mainNav .nav-link:hover {
  color: #fff !important;
}

.navbar-text {
  color: #ccc;
}

.navbar-text:hover {
  color: #ccc;
}

.logout-link {
  cursor: pointer;
}

.logout-link:hover {
  color: #fff !important;
}

.c-pointer {
  cursor: pointer;
}

.was-validated .form-control:invalid,
.was-validated .form-control:valid,
.was-validated .form-check-input:invalid,
.was-validated .form-check-input:valid {
  background-image: none;
  padding-right: 0px !important;
  border: 1px solid #ced4da !important;
}

.was-validated .form-select:invalid,
.was-validated .form-select:valid,
.was-validated .form-check-input:invalid,
.was-validated .form-check-input:valid {
  background-image: none !important;
  border: 1px solid #ced4da !important;
}

.lms-table > thead,
.lms-table > tbody {
  border: 1px solid #ddd;
}

.lms-table > tr > td {
  border-bottom: 1px solid #ddd;
}

.btnSubDetails .fa {
  color: green;
  font-size: 18px;
}

.btnSubDetails .fa-circle-plus {
  color: green;
}

.btnSubDetails .fa-circle-minus {
  color: red;
}

.loadingTd {
  text-align: center;
}

.card table tr th,
.card table tr td {
  padding: 5px;
}

.card table tr th {
  font-weight: 700;
}

.card table tr td,
.card table tr th,
.card table tr .form-control {
  font-size: 14px;
}

.card table tr th {
  width: 10rem !important;
}

.card-expand .card-header h4 {
  font-size: 14px !important;
  margin: 0px !important;
}
.step {
  height: 8px;
  background-color: #d3d3d3;
  width: 40px;
  display: inline;
  float: left;
  margin: 2px;
}

.indicator-2 > .current-stage,
.indicator-2 > .step {
  display: none;
}

.col-xs-3 > .card-expand > .card-header {
  padding: 10px 10px;
  background-color: #f2f2f2;
  margin-bottom: 10px !important;
}

.col-xs-12 .card-expand {
  border: 1px solid rgb(125, 125, 125, 0.3);
  padding: 0px 0px;
}

.lms-inner .row .col-3 {
  padding: 5px;
}

.col-3 .card-expand {
  min-height: 700px;
}

.card-special {
  min-height: 345px !important;
}

.cs2 {
  margin-top: 10px;
}

.card .form-control {
  border: unset;
  border-bottom: 1px solid #ddd;
}

p {
  margin: 0px;
}

.sub-status option {
  color: #fff !important;
}
.sub-status,
.sub-status option[value=""] {
  width: 150px !important;
  background-color: #f74f00 !important;
  color: #fff;
  border: 1px solid #fff;
}
.sub-status.callmsg,
.sub-status.msgtxt,
.sub-status.email,
.sub-status option[value="Call & Msg"],
.sub-status option[value="Email"],
.sub-status option[value="Msg &Txt"] {
  background-color: #fa8f5d !important;
}
.sub-status.followup,
.sub-status option[value="Follow Up"] {
  background-color: #ffbb00 !important;
}

.sub-status.assigned {
  background-color: #4dbd74 !important;
}
.sub-status.callback {
  background-color: #67b46a !important;
}
.sub-status.sold {
  background-color: #5d9cec !important;
}

.sub-status.recaptured {
  background-color: #c865e7 !important;
}

select[data-state="AL"],
select[data-state="IL"],
select[data-state="IA"],
select[data-state="AR"],
select[data-state="LA"],
select[data-state="MN"],
select[data-state="KS"],
select[data-state="MS"],
select[data-state="MO"],
select[data-state="NE"],
select[data-state="ND"],
select[data-state="OK"],
select[data-state="SD"],
select[data-state="TN"],
select[data-state="TX"],
select[data-state="WI"],
select.sub-state option[value="AL"],
select.sub-state option[value="IL"],
select.sub-state option[value="IA"],
select.sub-state option[value="AR"],
select.sub-state option[value="LA"],
select.sub-state option[value="MN"],
select.sub-state option[value="KS"],
select.sub-state option[value="MS"],
select.sub-state option[value="MO"],
select.sub-state option[value="NE"],
select.sub-state option[value="ND"],
select.sub-state option[value="OK"],
select.sub-state option[value="SD"],
select.sub-state option[value="TN"],
select.sub-state option[value="TX"],
select.sub-state option[value="WI"] {
  background-color: #ffff50 !important;
}
select[data-state="AK"],
select[data-state="HI"],
select.sub-state option[value="AK"],
select.sub-state option[value="HI"] {
  background-color: #dc95ff !important;
}
select[data-state="AZ"],
select[data-state="CA"],
select[data-state="NV"],
select[data-state="WA"],
select[data-state="OR"],
select.sub-state option[value="AZ"],
select.sub-state option[value="CA"],
select.sub-state option[value="NV"],
select.sub-state option[value="WA"],
select.sub-state option[value="OR"] {
  background-color: #b1c4e7 !important;
}
select[data-state="CO"],
select[data-state="ID"],
select[data-state="NM"],
select[data-state="UT"],
select[data-state="WY"],
select[data-state="MT"],
select.sub-state option[value="CO"],
select.sub-state option[value="ID"],
select.sub-state option[value="NM"],
select.sub-state option[value="UT"],
select.sub-state option[value="WY"],
select.sub-state option[value="MT"] {
  background-color: #c4dfb2 !important;
}
select[data-state="FL"],
select[data-state="GA"],
select[data-state="IN"],
select[data-state="KY"],
select[data-state="ME"],
select[data-state="MD"],
select[data-state="MA"],
select[data-state="MI"],
select[data-state="NH"],
select[data-state="NJ"],
select[data-state="NY"],
select[data-state="NC"],
select[data-state="OH"],
select[data-state="PA"],
select[data-state="SC"],
select[data-state="RI"],
select[data-state="VT"],
select[data-state="VA"],
select[data-state="WV"],
select[data-state="DC"],
select[data-state="CT"],
select[data-state="DE"],
select.sub-state option[value="FL"],
select.sub-state option[value="GA"],
select.sub-state option[value="IN"],
select.sub-state option[value="KY"],
select.sub-state option[value="ME"],
select.sub-state option[value="MD"],
select.sub-state option[value="MA"],
select.sub-state option[value="MI"],
select.sub-state option[value="NH"],
select.sub-state option[value="NJ"],
select.sub-state option[value="NY"],
select.sub-state option[value="NC"],
select.sub-state option[value="OH"],
select.sub-state option[value="PA"],
select.sub-state option[value="SC"],
select.sub-state option[value="RI"],
select.sub-state option[value="VT"],
select.sub-state option[value="VA"],
select.sub-state option[value="WV"],
select.sub-state option[value="DC"],
select.sub-state option[value="CT"],
select.sub-state option[value="DE"] {
  background-color: #f7c8ab !important;
}
select[data-state="AS"],
select[data-state="GU"],
select[data-state="MH"],
select[data-state="PW"],
select[data-state="MP"],
select[data-state="PR"],
select[data-state="VI"],
select[data-state="FM"],
select.sub-state option[value="AS"],
select.sub-state option[value="GU"],
select.sub-state option[value="MH"],
select.sub-state option[value="PW"],
select.sub-state option[value="MP"],
select.sub-state option[value="PR"],
select.sub-state option[value="VI"],
select.sub-state option[value="FM"] {
  background-color: #f7c8ab !important;
}

select[data-state] option {
  background-color: #fff !important;
}

select.timeZone {
  background-color: #fff;
}
select.timeZone option {
  background-color: #fff;
}

select.timeZone.eastern,
select.timeZone option[value="eastern"] {
  background-color: #fa8f5d !important;
}

select.timeZone.central,
select.timeZone option[value="central"] {
  background-color: #ffbb00 !important;
}

select.timeZone.mountain,
select.timeZone option[value="mountain"] {
  background-color: #4dbd74 !important;
}

select.timeZone.pacific,
select.timeZone option[value="pacific"] {
  background-color: #5d9cec !important;
}

select.timeZone.hawaii,
select.timeZone.alaska,
select.timeZone option[value="hawaii"],
select.timeZone option[value="alaska"] {
  background-color: #c865e7 !important;
}
select.timeZone.us-territories,
select.timeZone option[value="us-territories"] {
  background-color: #ff9fa0 !important;
}

.pagination {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: end;
  list-style-type: none;

  li a {
    border-radius: 5px;
    padding: 0.2rem 0.6rem;
    border: gray 1px solid;
    cursor: pointer;
    margin-right: 0.5rem;
  }

  li.break a {
    border-color: transparent;
  }
  li.previous a,
  li.next a {
    text-decoration: none;
    border-radius: 5px;
    padding: 0.2rem 0.3rem;
    border: gray 1px solid;
    cursor: pointer;
    margin-right: 0.5rem;
    color: inherit;
  }
  li.active a {
    background-color: #303234;
    border: 1px solid #000;

    color: #fff !important;
    min-width: 32px;
    margin-right: 0.5rem;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
}

button.flex-end.btn.btn-primary {
  margin-bottom: 1rem;
}

.data-message {
  text-align: center;
  height: 70vh;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-box {
  color: #f74f00;
  height: 70vh;
  width: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.spinner-border {
  width: 3.5rem;
  height: 3.5rem;
}

#MainTabs-tabpane-Working .nav-item .active,
#MainTabs-tabpane-Archive .nav-item .active {
  background: #f74f00;
  color: white !important;
  margin-right: 3px;

  padding: 10px;
  font-size: medium;
}

#MainTabs-tabpane-Working .nav-item button,
#MainTabs-tabpane-Archive .nav-item button {
  color: white !important;
  background: coral;
  margin-right: 3px;
  padding: 10px;
  font-size: medium;
}

.nav-link:hover {
  color: #0a58ca !important;
}

.searchForm {
  float: right;
}

.searchModal {
  min-width: 80vw;
}

.salesModal {
  min-width: 60vw;
}

.premium-label {
  padding: 1px 3px;
  font-size: 13px;
}

.btn-outline-success {
  color: #fff;
  background-color: #0b5ed7;
}

.un-read {
  background-color: #ffeee8 !important;
  color: #000;
}

.unread-sub-tab {
  border: 2px solid #fff;
  border-radius: 2px;
}

.filter-select {
  &__input-container {
    margin: 0px !important;
    padding: 0px !important;
  }
  &__value-container {
    padding: 0px 8px !important;
  }
  &__control {
    min-height: 30px !important;
  }
  &__clear-indicator {
    padding: 3px !important;
  }
  &__placeholder {
    color: #000 !important;
  }
}

.filter-btn {
  border: 1px solid #ddd;
}

.lead-filter {
  display: flex;
  justify-content: space-between;
}

.reset-btn {
  cursor: pointer;
  color: #3b80ba;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-controller {
  height: 33px;
}

.filter-select__input {
  padding: 0px !important;
}

.btn-primary {
  background-color: #337ab7 !important;
  border-color: #3277b4 !important;
}

.saleType {
  text-transform: capitalize;
}

.pdf-icon {
  position: fixed;
  top: 150px;
  right: 20px;
  font-size: 50px;
  color: rgb(255, 255, 255);
}

.refresh-icon {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 50px;
  color: rgb(255, 255, 255);
  border-radius: 50%;
  z-index: 99999;
}

@media (max-width: 575px) {
  .navbar-brand img {
    padding-right: 15px;
    width: 180px;
    margin-top: -10%;
  }
}
